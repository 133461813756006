a {
  &:hover {
    text-decoration: none;
  }
}

.navigation-wrapper {
  display: table;
  width: 100%;
  box-sizing: border-box;
  clear: both;
}

#main-menu {
  margin-top: 1rem;
  
  a {
    font-weight: 500;
    color: $text-color;
    text-transform: uppercase;
    
    &:hover {
      color: $link-color;
    }
  }
}

.nav-cta {
  float: left;
  padding-right: 1.142rem;
  
  a {
    font-weight: 500;
    text-transform: uppercase;
  }
}

@include media-breakpoint-up(md) {
  .navigation-wrapper {
    display: block;
    width: auto;
    box-sizing: border-box;
    clear: both;
  }

  #main-menu {
    margin-top: 0;
  }
}

@include media-breakpoint-up(lg) {
  .navbar-expand-md .navbar-nav .nav-link {
    padding-left: 1.142rem;
    padding-right: 1.142rem;
  }
}

/**
 * Carousel
 */

#myCarousel {
  .carousel-caption {
    background: rgba(255,255,255,0.60);
    padding: 32px 24px;
    width: 100%;
    max-width: 614px;
    left: 0;
    bottom: 90px;
    text-align: center;

    h1 {
      @include font-size(xl);
      font-weight: 500;
      color: $text-color;
      margin-top: 0.5rem;
    }

    p {
      @include font-size(m);
      color: $text-color;
      margin-bottom: 0;
    }

    p.lead {
      @include font-size(l);
      color: $text-color;
      margin-bottom: 1rem;
    }

    .btn {
      font-weight: 500;
      text-transform: uppercase;
    }
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    margin-top: 100px;
  }
}

@include media-breakpoint-up(md) {
  #myCarousel {
    .carousel-caption {
      text-align: left;
      
      h1 {
        @include font-size(xxl);
      }
      
      p {
        font-size: 18px;
      }
      
      p.lead {
        font-size: 21px;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  
}

/** Sections **/

.section {
  padding-top: 1.5rem;
  padding-bottom: 2rem;
}

h2.section-title {
  @include font-size(xxl);
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

/**
 * Services
 */

#services {
  .service {
    text-align: center;
    
    h3 {
      @include font-size(xl);
    }
    
    p {
      margin: 0;
    }
  }
}

/**
 * Portfolio
 */

#portfolio {
  background: #181c1f;
  
  .section-title {
    color: #ffffff;
  }
  
  .owl-carousel {
    .owl-nav {
      display: none;
    }
    
    .owl-dots {
      margin-top: 1.5rem;
    }
  }
}

/**
 * About
 */

#about {
  background: #eeeeee;

  .card-columns {
    @include media-breakpoint-only(sm) {
      column-count: 1;
    }
    @include media-breakpoint-only(md) {
      column-count: 2;
    }
    @include media-breakpoint-only(lg) {
      column-count: 2;
    }
    @include media-breakpoint-only(xl) {
      column-count: 4;
    }
  }
  
  .card-header {
    position: relative;
    padding: 0;
  }
  
  .card-title {
    position: absolute;
    left: 0;
    bottom: 28px;
    background: rgba(51,51,51,0.60);
    color: #fff;
    font-weight: normal;
    text-transform: uppercase;
    padding: 4px 8px;
    margin: 0;
  }

  .card-subtitle {
    position: absolute;
    left: 8px;
    bottom: 8px;
    background: rgba(30,144,255,0.60);
    color: #fff;
    font-weight: normal;
    padding: 4px 8px;
    margin: 0;
  }
}

/**
 * Clients
 */

#testimonials {
  .carousel-caption {
    color: #333333;
    
    blockquote {
      font-style: italic;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
  
  .client-profile-img {
    width: 56px;
    height: 56px;
    @include border-radius(50%);
  }
  
  blockquote {
    @include font-size(m);
  }
  
  .blockquote-footer {
    @include font-size(s);
  }
  
  a {
    color: $gray-600;
    
    &:hover {
      color: $link-color;
    }
  }
}

@include media-breakpoint-up(md) {
  #testimonials {
    .carousel-caption {
      width: 100%;
      max-width: 480px;
      margin: 0 auto;
    }

    blockquote {
      font-size: 21px;
    }

    .blockquote-footer {
      @include font-size($base-font-size);
    }
  }
}

/**
 * Contact
 */

.pane__title {
  display: none;
  @include font-size(xl);
}

form#contact-site-form {
  margin-bottom: 2rem;
  
  .form-item {
    margin-top: 1rem;
  }
  
  label {
    margin-bottom: 0.25rem;
  }
  
  input[type="text"] {
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  textarea {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  
  fieldset.captcha {
    margin-top: 1rem;
    
    legend,
    .fieldset-description {
      display: none;
    }
  }
  
  .form-submit {
    cursor: pointer;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid #007bff;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    color: #fff;
    background-color: #007bff;
    @include border-radius(4px);
    margin-top: 1rem;
    
    &:hover {
      background-color: #0062cc;
      border: 1px solid #0062cc;
    }
  }
}

.company-details {
  padding-top: 1rem;
  
  .company-detail {
    margin-bottom: 2rem;
  }
  
  h4 {
    @include font-size(xl);
    font-weight: normal;
    
    span {
      font-weight: 500;
    }
  }
  
  p {
    margin: 0;
  }
}
