.skip-link__wrapper {
  margin: 0;
}

header.header.fixed-top {
  position: fixed;
  width: 100%;
  top: -70px;
  left: 0;
  right: 0;
  background: #fff;
  transform: translateY(70px);
  transition: transform 500ms ease, background 500ms ease;
  -webkit-transition: transform 500ms ease, background 500ms ease;
}