h1.site-name {
  line-height: 1em;
  margin-bottom: 0;
  
  a {
    @include font-size(xxl);
    font-weight: 500;
    color: $primary !important;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
  }
}

.site-slogan {
  @include font-size(l);
}