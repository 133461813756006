/**
 * Footer
 */

footer {
  @include font-size(s);
  text-transform: uppercase;

  a {
    color: #6c757d;
    
    &:hover {
      color: #fff;
    }
  }

  a.telephone,
  a.email-link {
    text-transform: none;
    letter-spacing: 1px;
  }

  h5 {
    color: #ffffff;
    font-size: 1rem;
  }

  h5.brand-name {
    @include font-size(xl);
    font-weight: 500;
  }

  address {
    margin: 0;
  }

  .footer-columns {
    background: #181c1f;
    padding: 32px 0;
    color: #6c757d;
  }

  .footer-bottom {
    background: #0e1112;
    padding: 16px 0;
    color: #6c757d;

    a {
      color: #6c757d;
    }
  }
}